<template>
  <div v-if="isDisplayed" class="toast-wrapper">

    <div class="toast-content">

      <!-- Icon -->
      <img :src="icon" class="icon" alt=""/>

      <!-- Text -->
      <div class="text-wrapper">
        <p class="title">{{ title }}</p>
        <p v-if="subtext" v-html="subtext" class="subtext"></p>
        <slot name="subtext"/>
      </div>

      <!-- Close -->
      <img @click="toggle()" src="../../assets/icons/svg/ic_close_light_grey.svg" class="close-icon" alt=""/>
    </div>

  </div>
</template>

<script>
import {ref} from "@vue/composition-api";

export default {
  name: "Toast",
  props: {
    icon: {
      type: String,
      required: false,
      default: require('../../assets/icons/svg/ic_check_success.svg')
    },
    title: {
      type: String,
      required: true
    },
    subtext: {
      type: String,
      required: false
    },
    debounce: {
      default: 5000
    }
  },
  setup(props) {
    const isDisplayed = ref(false);
    let interval = null;

    function toggle() {
      isDisplayed.value = !isDisplayed.value;

      // if (isDisplayed.value) {
      //   interval = setTimeout(() => {
      //     toggle();
      //   }, props.debounce);
      // } else {
      //   clearTimeout(interval);
      // }
    }

    function reset() {
      isDisplayed.value = false;
    }

    return {
      isDisplayed,
      toggle,
      reset
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/css/base.mixins";
@import "../../assets/css/base.variables";

.toast-wrapper {
  @include position-elevation(fixed, $right: rem(40), $bottom: rem(40), $elevation: 110);
  background-color: white;
  border-radius: rem(5);
  box-shadow: rem(2) rem(4) rem(8) 0 rgba(70, 70, 70, 0.25);
  border-left: rem(8) solid var(--green-light);
  max-width: rem(440);
  padding: rem(16) rem(24);

  .toast-content {
    align-items: center;
    display: flex;
    position: relative;
  }

  .icon {
    height: rem(24);
    margin-right: rem(24);
    width: rem(24);
  }

  .close-icon {
    @include hover-active-pointer();
    height: rem(24);
    margin-left: rem(34);
    width: rem(24);
  }
}

// Toast - Not allowed
.not-allowed-toast {
  border-left: rem(8) solid var(--red-light);
}

// Text
.text-wrapper {
  text-align: left;

  .title {
    font-size: rem(17);
    font-weight: bold;
    line-height: rem(20);
    margin-bottom: rem(2);
  }

  .subtext {
    color: var(--gray-light-03);
    font-family: "Open Sans";
    font-size: rem(16);
    font-weight: normal;
    line-height: rem(22);

    ::v-deep a {
      text-align: left;
    }
  }
}
</style>
