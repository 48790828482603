<template>
  <div class="logs-tab">

    <!-- The table options should be visible only if there are conflicts logs. -->
    <div v-if="conflictedLogs.length > 0" class="table-options">
      <!-- Conflicted logs title -->
      <SectionTitleWithIcon hasBackground title="Conflicted logs" class="section-title"/>

      <!-- Resolve action -->
      <p v-if="!checkboxController.selectedRows.length" class="resolve-prompt">Select the conflicted logs you want to
        resolve</p>
      <DButton v-else
               @click.native="toggleResolveWarning()"
               text="Resolve"
               class="button-main button-resolve"/>
    </div>

    <!-- Table -->
    <CustomTable v-if="conflictedLogs.length > 0"
                 :options="tableOptions"
                 :table-data="allConflictedLogs"
                 :selected-rows="checkboxController.selectedRows"
                 :rows-with-expanded-details="checkboxController.hasDetailsOpen"
                 class="conflict-logs-table">
      <!-- Head -->
      <template #headcheckbox>
        <BasicCheckbox id="checkbox-head"
                       :is-checkbox-checked="checkboxController.isCheckboxAllChecked(allConflictedLogs)"
                       :callback="() => selectAllRows(allConflictedLogs)"
                       class="checkbox-black"/>
      </template>

      <!-- Data -->
      <template #checkbox="props">
        <BasicCheckbox :id="`checkbox-${props.rowData.id}`"
                       :is-checkbox-checked="checkboxController.isCheckboxChecked(props.rowData.id)"
                       :callback="() => selectRow(props.rowData)"/>
      </template>
      <template #date="props">
        {{ props.rowData.date }}
      </template>
      <template #order="props">
        <div class="order-cell">
          <Chip :text="props.rowData.orderType.type" :custom-class="props.rowData.orderType.color" class="order-chip"/>
          <span class="order-name">{{props.rowData.orderTitle}}</span>
        </div>
      </template>
      <template #employee="props">
        <span class="issue-text">{{ props.rowData.employee }}</span>
      </template>
      <template #changes="props">
        <span v-for="change in props.rowData.changes" :key="`id-${change.change}`" :class="change.changeClass"
              class="changes-chip">{{ change.change }}</span>
      </template>
      <template #actions="props">

        <!-- This part is controlled by the tableHelpers.js -->
        <!-- After the details are visible IssueDetails.vue is displayed. -->
        <div @click="toggleDetails(props.rowData)" class="details-toggle">
          <span :id="detailToggleId(props.rowData.id)" class="text">Show details</span>
          <img src="../../../../assets/icons/svg/ic_dropdown_active.svg" class="caret-icon" alt=""/>
        </div>
      </template>

    </CustomTable>
    <NoRecordsYet v-else text="No more conflicted logs" class="no-more"/>

    <!-- LogDetails.vue is added via the tableHelpers.js. -->

    <!-- All logs resolved -->
    <Toast title="Success" subtext="All Log conflicts have been successfully<br/> resolved." ref="logsResolvedToast"/>
  </div>
</template>

<script>
import CustomTable from "@/components/partials/table/CustomTable";
import {computed, onUnmounted, ref} from "@vue/composition-api";
import CheckboxController from "@/utils/controllers/CheckboxController";
import BasicCheckbox from "@/components/elements/BasicCheckbox";
import SectionTitleWithIcon from "@/components/elements/SectionTitleWithIcon";
import Chip from "@/components/elements/Chip";
import {areLogDetailsOpen, hideDetails, showLogsDetails} from "@/utils/helpers/tableHelpers";
import ConflictDetails from "@/components/partials/conflictManager/logs/LogDetails";
import DButton from "@/components/elements/DButton";
import {RESET_MODAL_OPTIONS, SET_MODAL_OPTIONS} from "@/store/mutations";
import {MODAL_CONTENT} from "@/models/ModalContent";
import NoRecordsYet from "@/components/partials/NoRecordsYet";
import {GET_CONFLICTED_LOGS, RESOLVE_CONFLICTED_LOGS} from "@/store/conflictManager/actions";
import Toast from "@/components/elements/Toast";
import {GET_CONFLICTED_PROJECTS} from "../../../../store/project/actions";
import getProject from "../../../../composables/projects/getProject";
import ConflictLogsManager from "../../../../utils/conflictManager/ConflictLogsManager";

export default {
  name: "Logs",
  components: {
    Toast,
    NoRecordsYet,
    DButton,
    ConflictDetails,
    Chip,
    SectionTitleWithIcon,
    BasicCheckbox,
    CustomTable
  },
  setup(props, {root, emit}) {
    const store = root.$store;
    const route = root.$route;

    const tableOptions = {
      headings: {
        'checkbox': '',
        'date': 'Conflict date',
        'order': 'Order',
        'employee': 'Employee',
        'changes': 'Changes',
        'actions': ''
      },
      editable: {
        'checkbox': false,
        'date': false,
        'order': false,
        'employee': false,
        'changes': false,
        'actions': false,
      },
      sortable: {
        'checkbox': false,
        'date': false,
        'order': false,
        'employee': false,
        'changes': false,
        'actions': false,
      }
    };

    // Get the ID of the project from the URL.
    const projectId = route.params.projectId;

    /** Project **/
    const {getProjectDetails} = getProject();
    getProjectDetails(projectId); // Get the details of the project to get the name.

    /** Checkboxes **/
    const checkboxController = ref(new CheckboxController());

    function selectRow(rowData) {
      const isCheckboxChecked = checkboxController.value.isCheckboxChecked(rowData.id);

      // Before selecting/unselecting the checkbox, we need to check if it checked.
      // If the row is unselected, the details are closed and upon row selection, we need to add the transitions to the logsToResolve array.
      if (!isCheckboxChecked) {
        ConflictLogsManager.addLogFromCheckbox(rowData); // Set the selected logs.
      } else {
        ConflictLogsManager.removeTransition(rowData.id); // Remove the selected logs from the logsToResolve array.
      }

      // Select/unselect the checkbox.
      checkboxController.value.selectRow(rowData);

      // If the details are open, they should be hidden (and the logs removed from the logsToResolve array).
      if (areLogDetailsOpen(rowData.id)) {
        const rowId = detailRowId(rowData.id);
        const toggleId = detailToggleId(rowData.id);

        // Close the details of this row.
        hideDetails(rowId, toggleId);

        // Remove the border to the row(s).
        checkboxController.value.setDetailsOpen(rowData);
      }

      // Update the parent.
      updateParent();
    }

    function selectAllRows() {
      // Add or remove the logs from the logsToResolve array.
      if (checkboxController.value.isCheckboxAllChecked(allConflictedLogs.value)) {
        ConflictLogsManager.removeMultipleTransitions(allConflictedLogs.value);
      } else {
        ConflictLogsManager.addMultipleTransitions(allConflictedLogs.value);
      }

      checkboxController.value.selectAllRows(allConflictedLogs.value); // Select all rows via the controller.
      updateParent(); // Inform the parent that the selected rows have changed.
    }

    function resetRows() {
      // Reset the selected rows.
      checkboxController.value.resetSelectedRows();

      // Inform the parent about the rows' reset.
      updateParent();
    }

    function updateParent() {
      emit('selected-rows', checkboxController.value.selectedRows); // Inform the parent that the selected rows have changed.
    }

    /** Conflicted logs **/
    const conflictedLogs = computed(() => store.getters.getConflictedLogs); // The conflicted logs divided into the orders.
    const allConflictedLogs = computed(() => {
      let items = []

      // We map the logs since the logs array has logs within each order.
      conflictedLogs.value.map(logs => {
        items = items.concat(logs.logs.map(log => log));
      })
      return items
    });
    getConflictedLogs();

    function getConflictedLogs() {
      return store.dispatch(GET_CONFLICTED_LOGS, projectId);
    }

    /** Resolve **/
    function toggleResolveWarning() {
      const modalContent = MODAL_CONTENT.RESOLVE_CHANGES(onLogsResolve);
      store.commit(SET_MODAL_OPTIONS, {modalContent: modalContent, activeModal: modalContent.name});
    }

    const logsResolvedToast = ref(null);

    function toggleToast() {
      if (!logsResolvedToast.value.isDisplayed) {
        logsResolvedToast.value.toggle();
      }
    }

    // Closes the details panel of each log (row) based on the row's ID (=logID).
    function closeDetailsByLogId() {
      // The ID of the row is the same as the ID of the elements in logsToResolve.
      // We need to get all the unique ID's from the logsToResolve and close their corresponding detail panels.
      const logIds = [...new Set(ConflictLogsManager.logsToResolve.map(log => log.id))];

      logIds.map(id => {
        // If the detail panel is open, close it.
        if (areLogDetailsOpen(id)) {
          toggleDetails({id: id});
        }
      });
    }

    function onLogsResolve() {
      resolveSelectedLogs().then(response => {
        if (response) {
          // Close the warning modal.
          store.commit(RESET_MODAL_OPTIONS);

          // Close the details panel from the table based on the row.
          closeDetailsByLogId();

          // Reset the selected rows.
          resetRows();

          // Reset the logsToResolve array.
          ConflictLogsManager.resetLogs();

          // Get the updated data from the API.
          getConflictedLogs().then(response => {
            if (response && allConflictedLogs.value.length === 0) {
              // If there are no more logs, the relevant toast should be displayed.
              toggleToast();
            }
          });

          // Make sure that the Conflicted projects notification is up-to-date.
          getConflictedProjects();
        }
      })
    }

    function resolveSelectedLogs() {
      return store.dispatch(RESOLVE_CONFLICTED_LOGS, {projectId, logs: ConflictLogsManager.logsToResolve});
    }

    /** Row details - Conflict details **/
    const detailRowId = (rowId) => `details-row-${rowId}`;
    const detailToggleId = (rowId) => `toggle-details-${rowId}`;

    function toggleDetails(rowData) {
      const rowId = detailRowId(rowData.id);
      const toggleId = detailToggleId(rowData.id);
      const isCheckboxChecked = checkboxController.value.isCheckboxChecked(rowData.id);

      // If the details are closed but the row is selected, the details should be displayed (but the row remains selected).
      if (!areLogDetailsOpen(rowData.id) && isCheckboxChecked) {
        showLogsDetails(rowData, 6);
      }

      // If the details are open (the row is selected), upon toggling, the details should close AND the row should be unselected.
      else if (areLogDetailsOpen(rowData.id)) {

        // Close the details of this row.
        hideDetails(rowId, toggleId);

        // Unselect the row.
        selectRow(rowData);

        // Make sure to remove the selected logs from the stored logsToResolve.
        // TODO: Could be transferred to the LogDetails.vue, if we unmount the component upon row removal (in tableHelpers.js).
        // removeSelectedLogsToResolveById(rowData.id);
      }

      // If the row is not selected, select it.
      else if (!isCheckboxChecked) {
        // When the user selects the details, the row is automatically selected.
        selectRow(rowData);

        // Display the log details.
        showLogsDetails(rowData, 6);
      }

      // Add the border to the row(s).
      checkboxController.value.setDetailsOpen(rowData);
    }

    onUnmounted(() => {
      // This is fired when the user changes the active tab.
      // In this case, we want to reset any stored data.
      ConflictLogsManager.resetLogs();
    });

    /** Conflicted projects data **/
    function getConflictedProjects() {
      return store.dispatch(GET_CONFLICTED_PROJECTS);
    }

    return {
      tableOptions,

      /** Checkboxes **/
      checkboxController,
      selectRow,
      selectAllRows,

      /** Conflicted logs **/
      conflictedLogs,
      allConflictedLogs,

      /** Changes table **/
      toggleDetails,

      /** Resolve **/
      toggleResolveWarning,
      logsResolvedToast,

      /** Row details - Conflict details **/
      detailToggleId,
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../../assets/css/base.mixins";

.logs-tab {
  text-align: left;

  //
  .table-options {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: rem(24);
    margin-top: rem(72);

    .resolve-prompt {
      color: var(--gray-light-03);
      font-family: "Open Sans";
      font-size: rem(16);
      line-height: rem(22);
    }

    .button-resolve {
      width: rem(131);
    }
  }

  // Table
  .order-cell {
    align-items: center;
    display: flex;

    .order-chip {
      flex-shrink: 0;
      margin-right: rem(15);
    }

    .order-name {
      font-size: rem(16);
    }
  }

  .details-toggle {
    @include hover-active-pointer();
    align-items: center;
    display: flex;
    justify-content: center;

    .text {
      font-family: "Open Sans";
      text-decoration: underline;
    }

    .caret-icon {
      height: rem(24);
      width: rem(24);
    }
  }
}

.no-more {
  margin-top: rem(80);
}
</style>
