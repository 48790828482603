import {ISSUE_CONFLICT_CHANGES} from "@/models/conflictManager/change/ConflictChange";

// Constants
export const conflictLogStates = {
  new: 'new',
  old: 'old'
}

class ConflictIssueManager {
  constructor() {
    this.issuesToResolve = [];
  }

  /**
   * Add
   */
  addSingleTransition(issue, state) {
    // The API expects an array of logs with the following structure: e.g: { id: '', comment: '' } OR { id: '', actionId: '' }
    const transition = this.formatTransition(issue, state);

    // Save the transitions to the local array.
    this.setIssue(transition);
  }

  addTransitionsFromCheckbox(row, state = conflictLogStates.new) {
    row.details.forEach(issue => this.addSingleTransition(issue, state));
  }

  addMultipleTransitions(issues, state = conflictLogStates.new) {
    issues.forEach(issue => this.addTransitionsFromCheckbox(issue));
  }

  setIssue(issue) {
    const index = this.issuesToResolve.findIndex(item => {
      // Get the item's type and compare it to the incoming transition's type.
      const hasSameType = Object.keys(item)[1] === Object.keys(issue)[1];
      return item.id === issue.id && hasSameType;
    });

    if (index !== -1) {
      this.issuesToResolve[index] = issue; // Replace the existing conflict with the new selected transition.
    } else {
      this.issuesToResolve.push(issue);
    }
  }

  /**
   * Remove
   */
  removeTransition(transitionId) {
    this.issuesToResolve = this.issuesToResolve.filter(log => log.id !== transitionId);
  }

  removeMultipleTransitions(issues) {
    issues.forEach(issue => {
      this.removeTransition(issue.id);
    })
  }

  resetIssues() {
    this.issuesToResolve = [];
  }

  /**
   * Formatting
   */
  formatTransition(issue, state) {
    const type = ISSUE_CONFLICT_CHANGES[issue.type.toUpperCase()];
    return {id: issue.conflictId, [type]: issue[state]};
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new ConflictIssueManager();
    }
    return this.instance;
  }
}

export default ConflictIssueManager.getInstance();