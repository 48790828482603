<template>
  <div class="conflict-details">

    <!-- If the conflict is on Comment or Hours -->
    <!-- Change section -->
    <div v-if="row.details">
      <div v-for="item in row.details" :key="item.type" class="change-section">
        <div class="change-title">{{ item.type }}</div>
        <TransitionSection @selected="(state) => ConflictLogsManager.addSingleLog(item, state)" :old-data="item.old" :new-data="item.new"/>
      </div>
    </div>

    <!-- If the conflict is on Deleted -->
    <!-- If the log has deleted, it will not have a details property. This means that we need to show the hardcoded details. -->
    <!-- Change section -->
    <div v-else class="change-section">
      <div class="change-title">action</div>
      <TransitionActions :project="projectDetails.name" @selected="(state) => ConflictLogsManager.setDeletedLog(row, state)"/>
    </div>
  </div>
</template>

<script>
import IssueBox from "@/components/partials/conflictManager/transitions/TransitionBox";
import TransitionSection from "@/components/partials/conflictManager/transitions/TransitionSection";
import TransitionActions from "@/components/partials/conflictManager/transitions/TransitionActions";
import {computed} from "@vue/composition-api";
import vuexStore from "@/store";
import ConflictLogsManager from "../../../../utils/conflictManager/ConflictLogsManager";

export default {
  name: "LogDetails",
  components: {
    TransitionActions,
    TransitionSection,
    IssueBox
  },
  props: {
    row: {},

    // Used to send data to the parent.
    transitionSelected: {
      type: Function
    }
  },
  emits: [],
  setup(props, {root}) {
    const store = vuexStore;
    const route = root.$route;

    const projectDetails = computed(() => store.getters.getSelectedProject);

    return {
      ConflictLogsManager,
      projectDetails
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../../assets/css/base.mixins";

// Change section
.change-section {

  &:not(:last-child) {
    margin-bottom: rem(32);
  }

  .change-title {
    border-radius: rem(18);
    background-color: var(--gray-main);
    display: inline-block;
    font-size: rem(17);
    font-weight: bold;
    line-height: rem(20);
    margin-bottom: rem(10);
    padding: rem(8) rem(16);
    text-transform: uppercase;
  }
}
</style>
