export default function getIssueConflictsTableHOptions() {
  const tableOptions = {
    headings: {
      'checkbox': '',
      'date': 'Conflict date',
      'orders': 'Orders',
      'issue': 'Issue',
      'changes': 'Changes',
      'actions': ''
    },
    editable: {
      'checkbox': false,
      'date': false,
      'orders': false,
      'issue': false,
      'changes': false,
      'actions': false,
    },
    sortable: {
      'checkbox': false,
      'date': false,
      'orders': false,
      'issue': false,
      'changes': false,
      'actions': false,
    }
  };

  return tableOptions;
}