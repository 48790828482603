<template>
  <div @click="$emit('selected', actionId)" :class="{'selected': isSelected}" class="action">
    <img :src="radioIcon" class="radio-icon" alt="radio button"/>
    <p class="action-text">
      <slot name="text"/>
    </p>
  </div>
</template>

<script>
import {computed} from "@vue/composition-api";

export default {
  name: "Action",
  props: {
    actionId: {
      type: Number,
      required: true
    },
    selectedAction: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const isSelected = computed(() => props.actionId === props.selectedAction);
    const radioIcon = computed(() => {
      const ext = isSelected.value ? 'checked' : 'unchecked';
      return require(`../../../../assets/icons/svg/ic_radio_${ext}_primary.svg`);
    });

    return {
      isSelected,
      radioIcon,
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../../assets/css/base.mixins";
@import "../../../../assets/css/base.mixins";

.action {
  @include hover-active-pointer();
  border: rem(1) solid var(--gray-main);
  border-radius: rem(10);
  display: flex;
  flex-grow: 0;
  margin-right: auto;
  padding: rem(16) rem(23) rem(16) rem(16);

  &:not(:last-child) {
    margin-bottom: rem(8);
  }

  .radio-icon {
    height: rem(20);
    margin-right: rem(16);
    width: rem(20);
  }

  .action-text {
    font-family: "Open Sans";
    font-size: rem(16);
    line-height: rem(22);
    opacity: 0.7;

    .text-bold {
      font-family: 'Montserrat', sans-serif;
      font-size: rem(17);
      font-weight: bold;
      line-height: rem(20);
      margin-right: rem(12);
    }
  }

  &.selected {
    border-color: var(--black-light);

    .action-text {
      opacity: 1;
    }
  }
}
</style>
