<template>
  <div class="transitions-wrapper">
    <!-- Old state -->
    <TransitionBox @click.native="selectState(conflictLogStates.old)"
                   :is-selected="selectedTransition === conflictLogStates.old"
                   is-old
                   :data="oldData"
                   class="transition-item"/>

    <!-- Arrow -->
    <img src="../../../../assets/icons/svg/ic_arrow_right_secondary.svg"
         :class="selectedTransition === conflictLogStates.new ? 'arrow-right' : 'arrow-left'"
         alt=""/>

    <!-- New state -->
    <TransitionBox @click.native="selectState(conflictLogStates.new)"
                   :is-selected="selectedTransition === conflictLogStates.new"
                   :data="newData"
                   class="transition-item"/>
  </div>
</template>

<script>
import {ref} from "@vue/composition-api";
import TransitionBox from "@/components/partials/conflictManager/transitions/TransitionBox";
import {conflictLogStates} from "@/utils/conflictManager/ConflictIssueManager";

export default {
  name: "TransitionSection",
  components: {
    TransitionBox
  },
  props: {
    oldData: {
      required: true
    },
    newData: {
      required: true
    }
  },
  emits: ['selected'],
  setup(props, {emit}) {
    const selectedTransition = ref('');
    selectState(conflictLogStates.new);

    function selectState(state) {
      selectedTransition.value = state;
      emit('selected', selectedTransition.value);
    }

    return {
      conflictLogStates,
      selectedTransition,
      selectState,
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../../assets/css/base.mixins";

.transitions-wrapper {
  align-items: center;
  display: flex;
  flex-basis: 100%;
  width: 100%;

  .transition-item {
    flex: 100%;
  }

  .arrow-right {
    height: rem(24);
    margin-left: rem(10);
    margin-right: rem(10);
    width: rem(24);
  }

  .arrow-left {
    height: rem(24);
    margin-left: rem(10);
    margin-right: rem(10);
    transform: rotate(180deg);
    width: rem(24);
  }
}
</style>
