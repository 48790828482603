<template>
  <div class="issues-tab">

    <!-- The table options should be visible only if there are conflicts issues. -->
    <div v-if="conflictedIssues.length > 0" class="table-options">
      <!-- Conflicted issues title -->
      <SectionTitleWithIcon hasBackground title="Conflicted issues" class="section-title"/>

      <!-- Resolve action -->
      <p v-if="!checkboxController.selectedRows.length" class="resolve-prompt">
        Select the conflicted issues you want to resolve
      </p>
      <DButton v-else
               @click.native="toggleResolveWarning()"
               text="Resolve"
               class="button-main button-resolve"/>
    </div>

    <!-- Table -->
    <CustomTable v-if="conflictedIssues.length > 0" :options="tableOptions"
                 :table-data="conflictedIssues"
                 :selected-rows="checkboxController.selectedRows"
                 :rows-with-expanded-details="checkboxController.hasDetailsOpen"
                 class="conflict-issues-table">
      <!-- Head -->
      <template #headcheckbox>
        <BasicCheckbox id="checkbox-head"
                       :is-checkbox-checked="checkboxController.isCheckboxAllChecked(conflictedIssues)"
                       :callback="() => selectAllRows(conflictedIssues)"
                       class="checkbox-black"/>
      </template>

      <!-- Data -->
      <template #checkbox="props">
        <BasicCheckbox :id="`checkbox-${props.rowData.id}`"
                       :is-checkbox-checked="checkboxController.isCheckboxChecked(props.rowData.id)"
                       :callback="() => selectRow(props.rowData)"/>
      </template>
      <template #date="props">
        {{ props.rowData.date }}
      </template>
      <template #orders="props">
        <div class="row-orders">
          <div v-for="order in props.rowData.orders" :key="order.id" class="order-item">
            <Chip :text="order.type.type" :custom-class="order.type.color"/>
            <p class="order-name">{{ order.title }}</p>
          </div>
        </div>
      </template>
      <template #issue="props">
        <!-- If the link is not present, the issue key is a simple text. -->
        <a v-if="props.rowData.link"
           :href="props.rowData.link"
           target="_blank"
           class="issue-text">
          {{ props.rowData.key }}
        </a>
        <span v-else>{{ props.rowData.issue.key }}</span>
      </template>
      <template #changes="props">
        <span v-for="change in props.rowData.changes"
              :key="`id-${change.change}`"
              :class="change.changeClass"
              class="changes-chip">{{ change.change }}</span>
      </template>
      <template #actions="props">
        <!-- This part is controlled by the tableHelpers.js -->
        <div @click="toggleDetails(props.rowData)" class="details-toggle">
          <span :id="detailToggleId(props.rowData.id)" class="text">Show details</span>
          <img src="../../../../assets/icons/svg/ic_dropdown_active.svg" class="caret-icon" alt=""/>
        </div>
      </template>
    </CustomTable>
    <NoRecordsYet v-else text="No more conflicted issues" class="no-more"/>

    <!-- IssueDetails.vue is added via the tableHelpers.js. -->

    <!-- All issues resolved -->
    <Toast title="Success"
           subtext="All issue conflicts have been successfully<br/> resolved."
           ref="issuesResolvedToast"/>
  </div>
</template>

<script>
import SectionTitleWithIcon from "@/components/elements/SectionTitleWithIcon";
import DButton from "@/components/elements/DButton";
import CustomTable from "@/components/partials/table/CustomTable";
import {computed, onUnmounted, ref} from "@vue/composition-api";
import CheckboxController from "@/utils/controllers/CheckboxController";
import BasicCheckbox from "@/components/elements/BasicCheckbox";
import {areIssueDetailsOpen, hideDetails, showIssueDetails} from "@/utils/helpers/tableHelpers";
import Chip from "@/components/elements/Chip";
import NoRecordsYet from "@/components/partials/NoRecordsYet";
import {GET_CONFLICTED_ISSUES, RESOLVE_CONFLICTED_ISSUES} from "@/store/conflictManager/actions";
import {MODAL_CONTENT} from "@/models/ModalContent";
import {RESET_MODAL_OPTIONS, SET_MODAL_OPTIONS} from "@/store/mutations";
import Toast from "@/components/elements/Toast";
import {GET_CONFLICTED_PROJECTS} from "../../../../store/project/actions";

import ConflictIssueManager from "../../../../utils/conflictManager/ConflictIssueManager";
import getIssueConflictsTableHOptions from "@/utils/tables/issueConflictsTableHelpers";

export default {
  name: "Issues",
  components: {
    Toast,
    NoRecordsYet,
    Chip,
    BasicCheckbox,
    CustomTable,
    DButton,
    SectionTitleWithIcon
  },
  setup(props, {root, emit}) {
    const store = root.$store;
    const route = root.$route;
    const tableOptions = getIssueConflictsTableHOptions();

    // Get the ID of the project from the URL.
    const projectId = route.params.projectId;

    /** Checkboxes **/
    const checkboxController = ref(new CheckboxController());

    function selectRow(rowData) {
      const isCheckboxChecked = checkboxController.value.isCheckboxChecked(rowData.id);

      // Before selecting/unselecting the checkbox, we need to check if it checked.
      // If the row is unselected, the details are closed and upon row selection, we need to add the transitions to the selectedIssuesToResolve array.
      if (!isCheckboxChecked) {
        ConflictIssueManager.addTransitionsFromCheckbox(rowData); // Set the selected issues to the selectedIssuesToResolveById array.
      } else {
        ConflictIssueManager.removeTransition(rowData.id); // Remove the selected issues from the selectedIssuesToResolveById array.
      }

      // Select/unselect the checkbox.
      checkboxController.value.selectRow(rowData);

      // If the details are open, they should be hidden (and the issues removed from the selectedIssuesToResolve array).
      if (areIssueDetailsOpen(rowData.id)) {
        const rowId = detailRowId(rowData.id);
        const toggleId = detailToggleId(rowData.id);

        // Close the details of this row.
        hideDetails(rowId, toggleId);

        // Remove the border to the row(s).
        checkboxController.value.setDetailsOpen(rowData);
      }

      // Update the parent.
      updateParent();
    }

    function resetRows() {
      // Reset the selected rows.
      checkboxController.value.resetSelectedRows();

      // Inform the parent about the rows' reset.
      updateParent();
    }

    function selectAllRows() {
      // Add or remove the logs from the selectedIssuesToResolveById array.
      if (checkboxController.value.isCheckboxAllChecked(conflictedIssues.value)) {
        ConflictIssueManager.removeMultipleTransitions(conflictedIssues.value);
      } else {
        ConflictIssueManager.addMultipleTransitions(conflictedIssues.value);
      }

      // Select all rows via the controller.
      checkboxController.value.selectAllRows(conflictedIssues.value);

      // Inform the parent that the selected rows have changed.
      updateParent();
    }

    function closeDetailsByIssueId() {
      // The ID of the row is the same as the ID of the elements in selectedIssuesToResolve.
      // We need to get all the unique ID's from the selectedIssuesToResolve and close their corresponding detail panels.
      const logIds = [...new Set(ConflictIssueManager.issuesToResolve.map(log => log.id))];

      logIds.forEach(id => {
        // If the details panel is open, close it.
        if (areIssueDetailsOpen(id)) {
          toggleDetails({id: id});
        }
      });
    }

    /** Resolve issues**/
    function toggleResolveWarning() {
      const modalContent = MODAL_CONTENT.RESOLVE_ISSUE_CHANGES(onIssuesResolve);
      store.commit(SET_MODAL_OPTIONS, {modalContent: modalContent, activeModal: modalContent.name});
    }

    const issuesResolvedToast = ref(null);

    function toggleToast() {
      if (!issuesResolvedToast.value.isDisplayed) {
        issuesResolvedToast.value.toggle();
      }
    }

    function onIssuesResolve() {
      resolveSelectedIssues().then(response => {
        if (response) {
          // Close the warning modal.
          store.commit(RESET_MODAL_OPTIONS);

          // Close the details panel from the table based on the row.
          closeDetailsByIssueId();

          // Reset the selected rows.
          resetRows();

          // Reset the selectedLogsToResolve array.
          ConflictIssueManager.resetIssues();

          // Get the updated data from the API.
          getConflictedIssues().then(response => {
            if (response && conflictedIssues.value.length === 0) {
              // If there are no more logs, the relevant toast should be displayed.
              toggleToast();
            }
          });

          // Make sure that the Conflicted projects notification is up-to-date.
          getConflictedProjects();
        }
      })
    }

    function resolveSelectedIssues() {
      return store.dispatch(RESOLVE_CONFLICTED_ISSUES, {projectId, issues: ConflictIssueManager.issuesToResolve});
    }

    /** Conflicted issues **/
    const conflictedIssues = computed(() => store.getters.getConflictedIssues);
    getConflictedIssues();

    function getConflictedIssues() {
      return store.dispatch(GET_CONFLICTED_ISSUES, projectId);
    }

    // Inform the parent about the new selected values.
    function updateParent() {
      emit('selected-rows', checkboxController.value.selectedRows);
    }

    /** Row details - Conflict details **/
    const detailRowId = (rowId) => `issue-details-row-${rowId}`;
    const detailToggleId = (rowId) => `toggle-issue-details-${rowId}`;

    function toggleDetails(rowData) {
      const rowId = detailRowId(rowData.id);
      const toggleId = detailToggleId(rowData.id);
      const isCheckboxChecked = checkboxController.value.isCheckboxChecked(rowData.id);

      // If the details are closed but the row is selected, the details should be displayed (but the row remains selected).
      if (!areIssueDetailsOpen(rowData.id) && isCheckboxChecked) {
        showIssueDetails(rowData, 6);
      }

      // If the details are open (the row is selected), upon toggling, the details should close AND the row should be unselected.
      else if (areIssueDetailsOpen(rowData.id)) {

        // Close the details of this row.
        hideDetails(rowId, toggleId);

        // Unselect the row.
        selectRow(rowData);
      }

      // If the row is not selected, select it.
      else if (!isCheckboxChecked) {
        // When the user selects the details, the row is automatically selected.
        selectRow(rowData);

        // Display the issue details.
        showIssueDetails(rowData, 6);
      }

      // Add the border to the row(s).
      checkboxController.value.setDetailsOpen(rowData);
    }

    onUnmounted(() => {
      // This is fired when the user changes the active tab.
      // In this case, we want to reset any stored data.
      ConflictIssueManager.resetIssues();
    });

    /** Conflicted projects data **/
    function getConflictedProjects() {
      return store.dispatch(GET_CONFLICTED_PROJECTS);
    }

    return {
      tableOptions,

      /** Resolve issues **/
      toggleResolveWarning,
      issuesResolvedToast,

      /** Conflicted issues **/
      conflictedIssues,

      /** Checkboxes **/
      checkboxController,
      selectRow,
      selectAllRows,

      /** Row details - Conflict details **/
      detailToggleId,
      toggleDetails
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../../assets/css/base.mixins";

.issues-tab {

  .table-options {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: rem(24);
    margin-top: rem(72);

    .resolve-prompt {
      color: var(--gray-light-03);
      font-family: "Open Sans";
      font-size: rem(16);
      line-height: rem(22);
    }

    .button-resolve {
      width: rem(131);
    }
  }
}

.row-orders {

  .order-item {
    align-items: center;
    display: flex;

    &:not(:last-child) {
      margin-bottom: rem(16);
    }

    .order-name {
      margin-left: rem(10);
    }
  }
}

.issue-text {
  color: var(--red-main);

  @include on-hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.details-toggle {
  @include hover-active-pointer();
  align-items: center;
  display: flex;
  justify-content: center;

  .text {
    font-family: "Open Sans";
    text-decoration: underline;
  }

  .caret-icon {
    height: rem(24);
    width: rem(24);
  }
}

.no-more {
  margin-top: rem(80);
}
</style>
