export default class Tab {
  constructor(id, text, tabName, errorCount) {
    this.id = id;
    this.text = text;
    this.tabName = tabName;
    this.errorCount = errorCount;
  }

  static parseDataFromObject(data) {
    return new Tab(data.id, data.text, data.errorCount);
  }
}
