<template>
  <div class="move-actions">
    <Action :action-id="deletedConflictLogStates.keep" :selected-action="selectedAction" @selected="selectAction">
      <template #text>
        <span class="text-bold">KEEP IN</span> {{ project }}
      </template>
    </Action>

    <Action :action-id="deletedConflictLogStates.remove" :selected-action="selectedAction" @selected="selectAction">
      <template #text>
        <span class="text-bold">REMOVE FROM</span> {{ project }}
      </template>
    </Action>
  </div>
</template>

<script>
import Action from "@/components/partials/conflictManager/issues/Action";
import {ref} from "@vue/composition-api";
import {deletedConflictLogStates} from "@/utils/conflictManager/ConflictLogsManager";

export default {
  name: "TransitionActions",
  components: {
    Action
  },
  props: {
    project: {
      type: String,
      required: false
    }
  },
  emits: ['selected'],
  setup(props, {emit}) {
    const selectedAction = ref(null);
    selectAction(deletedConflictLogStates.keep);

    function selectAction(action) {
      emit('selected', action);
      selectedAction.value = action;
    }

    return {
      deletedConflictLogStates,
      selectedAction,
      selectAction
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../../assets/css/base.mixins";
@import "../../../../assets/css/base.mixins";

.move-actions {
  display: flex;
  flex-direction: column;
}

</style>
