import { render, staticRenderFns } from "./IssueDetails.vue?vue&type=template&id=4ad82dc7&scoped=true&"
import script from "./IssueDetails.vue?vue&type=script&lang=js&"
export * from "./IssueDetails.vue?vue&type=script&lang=js&"
import style0 from "./IssueDetails.vue?vue&type=style&index=0&id=4ad82dc7&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ad82dc7",
  null
  
)

export default component.exports