<template>
  <div :class="{'selected': isSelected}" class="issue-box">

    <p class="type">{{ isOld ? 'old' : 'new' }}</p>
    <p class="key">{{ formattedData }}</p>
  </div>
</template>

<script>
import {computed} from "@vue/composition-api";

export default {
  name: "TransitionBox",
  props: {
    data: {
      required: true
    },
    isSelected: {
      type: Boolean,
      required: false
    },
    isOld: {
      type: Boolean,
      required: false
    }
  },
  setup(props) {
    const formattedData = computed(() => {
      // If the data is number, we can round the number to 2 digits.
      if (typeof props.data === 'number') {
        const percentage = props.data.toFixed(2);
        return Number(percentage);
      }
      return props.data;
    });

    return {
      formattedData
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../../assets/css/base.mixins";

.issue-box {
  @include hover-active-pointer;
  align-items: center;
  border: rem(1) solid;
  border-color: var(--gray-main);
  border-radius: rem(10);
  color: var(--gray-light-03);
  display: flex;
  padding: rem(24);

  .type {
    font-size: rem(17);
    font-weight: bold;
    line-height: rem(20);
    margin-right: rem(32);
    text-transform: uppercase;
  }

  .key {
    font-family: "Open Sans";
    font-size: rem(16);
    line-height: rem(22);
  }

  // Active
  &.selected {
    border-color: var(--green-light);
    box-shadow: rem(2) rem(4) rem(8) 0 rgba(70, 70, 70, 0.1);
    color: var(--black-light);
  }

  // Old type
  //&.old {
  //  border-color: var(--gray-main);
  //  color: var(--gray-light-03);
  //}
  //
  //// New type
  //&.new {
  //  border-color: var(--green-light);
  //  box-shadow: rem(2) rem(4) rem(8) 0 rgba(70, 70, 70, 0.1);
  //  color: var(--black-light);
  //}
}
</style>
