<template>
  <div class="tabs-wrapper">
    <div v-for="tab in tabs"
         :key="tab.id"
         @click="$emit('select-tab', tab)"
         :class="{'active': activeTab.tabName === tab.tabName}" class="tab">
      <span class="tab-text">{{ tab.text }}</span>
      <div v-if="tab.errorCount > 0" class="tab-count">{{ tab.errorCount }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tab",
  props: {
    tabs: {
      type: Array,
      required: true
    },
    activeTab: {
      type: Object,
      required: true
    }
  },
  emits: ['select-tab'],
  setup(props) {

  }
}
</script>

<style scoped lang="scss">
@import "../../assets/css/base.mixins";
@import "../../assets/css/base.variables";

.tabs-wrapper {
  display: flex;
  border-bottom: rem(2) solid var(--gray-main)
}

.tab {
  @include hover-active-pointer();
  align-items: center;
  display: flex;
  padding: rem(12) rem(37);

  &-text {
    color: var(--gray-light-03);
    font-size: rem(20);
    line-height: rem(24);
    margin-right: rem(15);
  }

  &-count {
    align-items: center;
    background-color: var(--red-light);
    border-radius: 50%;
    color: white;
    display: flex;
    font-size: rem(10);
    font-weight: bold;
    justify-content: center;
    line-height: rem(13);
    height: rem(22);
    width: rem(22);
  }

  &.active {
    border-bottom: rem(2) solid var(--black-light);


    .tab-text {
      color: var(--black-light);
      font-weight: bold;
    }
  }
}
</style>
