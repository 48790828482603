<template>
  <div class="conflict-details">

    <!-- The issue tab only has changes in Comment. -->
    <div class="issue-section">
      <div v-for="item in row.details" :key="item.type" class="change-section">
        <div class="change-title">{{ item.type }}</div>
        <TransitionSection @selected="(state) => ConflictIssueManager.addSingleTransition(item, state)" :old-data="item.old" :new-data="item.new"/>
      </div>
    </div>
  </div>
</template>

<script>
import TransitionSection from "@/components/partials/conflictManager/transitions/TransitionSection";
import vuexStore from "@/store";
import {computed} from "@vue/composition-api";
import ConflictIssueManager from "../../../../utils/conflictManager/ConflictIssueManager";

export default {
  name: "IssueDetails",
  components: {
    TransitionSection,
  },
  props: {
    row: {
      type: Object,
      required: true
    }
  },
  setup(props, {root}) {
    const store = vuexStore;

    const projectDetails = computed(() => store.getters.getSelectedProject);

    return {
      ConflictIssueManager,
      projectDetails,
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../../assets/css/base.mixins";

// Move section
.issue-section {
  text-align: left;

  &:not(:last-child) {
    margin-bottom: rem(32);
  }

  .change-title {
    border-radius: rem(18);
    background-color: var(--gray-main);
    display: inline-block;
    font-size: rem(17);
    font-weight: bold;
    line-height: rem(20);
    margin-bottom: rem(10);
    padding: rem(8) rem(16);
    text-transform: uppercase;
  }
}
</style>
