// EXAMPLE:
// MY_ITEM: (callback, cancelCallback) => ({
//   title: 'My title',
//   text: 'My text',
//   callback: callback,
//   cancelCallback: cancelCallback,
//
//   // Buttons
//   buttonLeft: 'No',
//   buttonLeftClass: 'button-clear-red modal-button-100',
//   buttonRight: 'Yes',
//   buttonRightClass: 'modal-button-100 my-class another-class',
//
//   // Modal name
//   name: 'myModalsName'
// }),

export const MODAL_NAMES = {
  // PROJECT OVERVIEW
  CREATE_PROJECT: 'createProject',
  EDIT_PROJECT: 'editProject',
  DELETE_PROJECT: 'deleteProject',

  // ORDERS
  CREATE_ORDER: 'createOrder',
  EDIT_ORDER: 'editOrder',
  DELETE_ORDER: 'deleteOrder',

  // CONFLICT MANAGER
  RESOLVE_CHANGES: 'resolveChanges',
  RESOLVE_ISSUE_CHANGES: 'resolveIssueChanges',
  APPLY_RESOLUTIONS: 'applyResolutions',

  // LOGS - SHARED
  SPECIFY_RATIO_CHANGE_REASON: 'specifyRatioChangeReason',
  SPECIFY_EXPORT_PRESET_CHANGE_REASON: 'specifyExportPresetChangeReason',
  SPECIFY_CHANGE_REASON: (modalId) => `specifyChangeReason${modalId}`,

  // USERS
  CREATE_USER: 'createUser',
  DELETE_USERS: 'deleteUsers',
}

export const MODAL_CONTENT = {
  // PROJECT OVERVIEW
  CREATE_PROJECT: (callback) => ({
    title: 'Create a project',
    // text: '',
    callback: callback,

    // Buttons
    buttonLeft: 'Cancel',
    buttonRight: 'Create',

    // Modal name
    name: MODAL_NAMES.CREATE_PROJECT
  }),
  EDIT_PROJECT: (callback, cancelCallback) => ({
    title: 'Edit project',
    callback: callback,
    cancelCallback: cancelCallback,

    // Buttons
    buttonLeft: 'Cancel',
    buttonLeftClass: 'button-clear-red modal-button-100',
    buttonRight: 'Save',
    buttonRightClass: 'modal-button-100',

    // Modal name
    name: MODAL_NAMES.EDIT_PROJECT
  }),
  DELETE_PROJECT: (callback, cancelCallback) => ({
    title: 'Delete project?',
    text: 'Are you sure you want to unassign this project? All orders and hour logs will be removed from Billy.',
    callback: callback,
    cancelCallback: cancelCallback,

    // Buttons
    buttonLeft: 'Cancel',
    buttonRight: 'Delete',

    // Modal name
    name: MODAL_NAMES.DELETE_PROJECT
  }),

  // ORDERS
  CREATE_ORDER: () => ({
    title: 'Create new order',
    // callback: callback,

    // Buttons
    buttonLeft: 'Cancel',
    buttonRight: 'Create order',

    // Modal name
    name: MODAL_NAMES.CREATE_ORDER
  }),
  EDIT_ORDER: (callback) => ({
    title: 'Edit order',
    callback: callback,

    // Buttons
    buttonLeft: 'Cancel',
    buttonLeftClass: 'button-clear-red modal-button-100',
    buttonRight: 'Save',
    buttonRightClass: 'modal-button-100',

    // Modal name
    name: MODAL_NAMES.EDIT_ORDER
  }),
  DELETE_ORDER: (callback, cancelCallback) => ({
    title: 'Delete order',
    text: 'Are you sure you want to delete this order? All the logs assigned to this order will be issues back to you unassigned hours.',
    callback: callback,
    cancelCallback: cancelCallback,

    // Buttons
    buttonLeft: 'Cancel',
    buttonLeftClass: 'button-border modal-button-100',
    buttonRight: 'Delete',
    buttonRightClass: 'modal-button-100',

    // Modal name
    name: MODAL_NAMES.DELETE_ORDER
  }),

  // CONFLICT MANAGER
  RESOLVE_CHANGES: (callback) => ({
    title: 'Resolve conflicts?',
    callback: callback,
    text: 'Are you sure you want to resolve these conflicts? Items that are not selected will remain in their conflicted state.',

    // Buttons
    buttonLeft: 'Cancel',
    buttonRight: 'Resolve',

    // Modal name
    name: MODAL_NAMES.RESOLVE_CHANGES
  }),
  RESOLVE_ISSUE_CHANGES: (callback) => ({
    title: 'Resolve conflicts?',
    callback: callback,
    text: 'Are you sure you want to resolve these conflicts? Items that are not selected will remain in their conflicted state.',

    // Buttons
    buttonLeft: 'Cancel',
    buttonRight: 'Resolve',

    // Modal name
    name: MODAL_NAMES.RESOLVE_ISSUE_CHANGES
  }),
  APPLY_RESOLUTIONS: (callback) => ({
    title: 'Apply resolutions?',
    callback: callback,
    text: 'Are you sure you want to apply your chosen resolutions for the selected conflicts?',

    // Buttons
    buttonLeft: 'Cancel',
    buttonRight: 'Apply',

    // Modal name
    name: MODAL_NAMES.APPLY_RESOLUTIONS
  }),

  // LOGS - SHARED
  SPECIFY_RATIO_CHANGE_REASON: (callback, cancelCallback) => ({
    title: 'Specify reason',
    callback: callback,
    cancelCallback: cancelCallback,

    // Buttons
    buttonLeft: 'Don\'t specify',
    buttonRight: 'Confirm',

    // Modal name
    name: MODAL_NAMES.SPECIFY_RATIO_CHANGE_REASON
  }),
  SPECIFY_EXPORT_PRESET_CHANGE_REASON: (callback, cancelCallback) => ({
    title: 'Specify reason',
    callback: callback,
    cancelCallback: cancelCallback,

    // Buttons
    buttonLeft: 'Don\'t specify',
    buttonRight: 'Confirm',

    // Modal name
    name: MODAL_NAMES.SPECIFY_EXPORT_PRESET_CHANGE_REASON
  }),
  SPECIFY_CHANGE_REASON: (callback, cancelCallback, modalId) => ({
    title: 'Specify reason',
    callback: callback,
    cancelCallback: cancelCallback,

    // Buttons
    buttonLeft: 'Don\'t specify',
    buttonRight: 'Confirm',

    // Modal name
    name: MODAL_NAMES.SPECIFY_CHANGE_REASON(modalId) // There can be several similar modals, so we need a seperate ID.
  }),

  // USERS
  CREATE_USER: {
    title: 'Add user',

    // Buttons
    buttonLeft: 'Cancel',
    buttonRight: 'Add',

    // Modal name
    name: MODAL_NAMES.CREATE_USER
  },
  DELETE_USERS: (count, callback) => ({
    title: 'Delete user(s)?',
    text: `Are you sure you want to delete <strong>${count}</strong> user(s)? They will no longer have access to the Billy.`,
    callback: callback,

    // Buttons
    buttonLeft: 'Cancel',
    buttonRight: 'Delete',

    // Modal name
    name: MODAL_NAMES.DELETE_USERS
  })
}
