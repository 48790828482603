import {conflictLogStates} from "@/utils/conflictManager/ConflictIssueManager";

// Values from API.
export const deletedConflictLogStates = {
  remove: 1,
  keep: 2,
}


class ConflictLogsManager {
  constructor() {
    this.logsToResolve = [];
  }

  /**
   * Add
   */
  addSingleLog(log, state) {
    this.setDefaultLog(log, state);
  }

  addMultipleTransitions(logs, state = conflictLogStates.new) {
    logs.forEach(log => this.addLogFromCheckbox(log));
  }

  addLogFromCheckbox(log, state = conflictLogStates.new) {
    // If the row does not have details, it means that it is a DELETED conflict.
    const isDeletedConflict = !log.details;

    if (isDeletedConflict) {
      this.setDeletedLog(log); // The default log state should be 'keep'.
      return;
    }

    log.details.forEach(log => this.setDefaultLog(log));
  }

  setDefaultLog(log, state) {
    // The API expects an array of logs with the following structure: { id: '', comment: '' } OR { id: '', actionId: '' }
    const transition = {id: log.conflictId, [log.type]: log[state]};

    // Save the transitions to the local array.
    this.setLog(transition)
  }

  setDeletedLog(log, state = deletedConflictLogStates.keep) {
    // The API expects an array of logs with the following structure: { id: '', comment: '' } OR { id: '', actionId: '' }
    const transition = {"id": log.id, "actionId": state};

    // Save the transitions to the local array.
    this.setLog(transition);
  }

  setLog(issue) {
    const index = this.logsToResolve.findIndex(item => {
      // Get the item's type and compare it to the incoming transition's type.
      const hasSameType = Object.keys(item)[1] === Object.keys(issue)[1];
      return item.id === issue.id && hasSameType;
    });

    if (index !== -1) {
      this.logsToResolve[index] = issue; // Replace the existing conflict with the new selected transition.
    } else {
      this.logsToResolve.push(issue);
    }
    console.log(this.logsToResolve);
  }

  /**
   * Remove
   */
  removeTransition(transitionId) {
    this.logsToResolve = this.logsToResolve.filter(log => log.id !== transitionId);
    console.log(this.logsToResolve)
  }

  removeMultipleTransitions(issues) {
    issues.forEach(issue => {
      this.removeTransition(issue.id);
    })
    console.log(this.logsToResolve)
  }

  resetLogs() {
    this.logsToResolve = [];
  }


  static getInstance() {
    if (!this.instance) {
      this.instance = new ConflictLogsManager();
    }
    return this.instance;
  }
}

export default ConflictLogsManager.getInstance();
