<template>
  <div>
    <!-- Title -->
    <TitleWithInfo :editable="false"
                   :chip-options="chipOptions"
                   title="Jira conflicts"
                   :date-tag="`Since: ${earliestConflictDate}`"
                   class="conflict-title"/>

    <!-- Tabs and tab content -->
    <!-- The tabs are: 1. Logs.vue, 2. Issues.vue -->
    <Tabs :tabs="tabs" :active-tab="activeTab" @select-tab="updateActiveTab"/>
    <component :is="activeTab.tabName"></component>

    <!-- Modal -->
    <Modal/>
  </div>
</template>

<script>
import TitleWithInfo from "@/components/elements/TitleWithInfo";
import ChipOption, {CHIP_STYLES} from "@/models/ChipOption";
import Tabs from "@/components/partials/Tabs";
import {computed, ref} from "@vue/composition-api";
import Logs from "@/components/partials/conflictManager/logs/Logs";
import Issues from "@/components/partials/conflictManager/issues/Issues";
import Tab from "@/models/Tab";
import Modal from "@/components/partials/Modal";
import {GET_CONFLICTED_ISSUES, GET_CONFLICTED_LOGS} from "@/store/conflictManager/actions";
import {GET_PROJECT_BY_ID} from "@/store/project/actions";
import {pluralizeRegularString} from "@/utils/globals";
import getProject from "../../composables/projects/getProject";

const tabNames = {
  logs: 'logs',
  issues: 'issues',
}

export default {
  name: "ConflictManager",
  components: {
    Modal,
    TitleWithInfo,
    Tabs,
    Issues,
    Logs,
  },
  setup(props, {root}) {
    const store = root.$store;
    const route = root.$route;

    // Get the ID of the project from the URL.
    const projectId = route.params.projectId;

    /** Project details **/
    const {getProjectDetails} = getProject();
    getProjectDetails(projectId); // Get the details of the project so can get the name.

    /** Conflict data **/
    getConflictedIssues();
    getConflictedLogs();

    function getConflictedIssues() {
      return store.dispatch(GET_CONFLICTED_ISSUES, projectId);
    }

    function getConflictedLogs() {
      return store.dispatch(GET_CONFLICTED_LOGS, projectId);
    }

    const conflictedIssues = computed(() => store.getters.getConflictedIssues);

    // The array of all logs (from all the orders).
    const allConflictedLogs = computed(() => {
      let items = []

      // We map the logs since the logs array has logs within each order.
      store.getters.getConflictedLogs.map(logs => {
        items = items.concat(logs.logs.map(log => log));
      })
      return items
    });

    const conflictedLogsCount = computed(() => allConflictedLogs.value.length ? allConflictedLogs.value?.length : 0);

    const conflictedIssuesCount = computed(() => store.getters.getConflictedIssues.length);

    // All the conflicts (logs + issues)
    const totalConflictsCount = computed(() => conflictedLogsCount.value + conflictedIssuesCount.value);

    /** Title with info **/
    const chipOptions = computed(() => {
      const count = totalConflictsCount.value;
      const text = pluralizeRegularString(count, 'conflict');
      return [new ChipOption(`${count} ${text}`, CHIP_STYLES.LIGHT_RED)];
    });

    const earliestConflictDate = computed(() => {
      let earliestIssueDate, earliestLogDate = [];

      // Get the earliest issue date.
      if (conflictedIssues.value?.length) {
        const issueDates = conflictedIssues.value.map(issue => issue.date);
        earliestIssueDate = issueDates.reduce((dateA, dateB) => dateA < dateB ? dateA : dateB);
      }

      // Get the earliest log date.
      if (allConflictedLogs.value?.length) {
        const logDates = allConflictedLogs.value.map(issue => issue.date);
        earliestLogDate = logDates.reduce((dateA, dateB) => dateA < dateB ? dateA : dateB);
      }

      // Compare the log and issue dates and return the earliest.
      return earliestIssueDate < earliestLogDate ? earliestIssueDate : earliestLogDate;
    })

    /** Tabs **/
    const tabs = computed(() => [
      new Tab(0, 'Logs', tabNames.logs, conflictedLogsCount.value),
      new Tab(1, 'Issues', tabNames.issues, conflictedIssuesCount.value),
    ]);
    const activeTab = ref(tabs.value[0]); // Upon page load, set the active tab to the logs tab.

    function updateActiveTab(tab) {
      activeTab.value = tab;
    }

    return {
      /** Conflict data **/
      totalConflictsCount,
      earliestConflictDate,

      /** Title with info **/
      chipOptions,

      /** Tabs **/
      tabs,
      activeTab,
      updateActiveTab
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/css/base.mixins";

.conflict-title {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 40%;
  margin-bottom: rem(65);
}
</style>
