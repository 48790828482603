import Vue from 'vue';
import LogDetails from "@/components/partials/conflictManager/logs/LogDetails";
import IssueDetails from "@/components/partials/conflictManager/issues/IssueDetails";
import {ref} from "@vue/composition-api";

export function hideDetails(rowId, toggleId) {
  const row = document.getElementById(rowId);

  // Change the text of the toggle button.
  document.getElementById(toggleId).innerText = 'Show details';

  // Remove row.
  row.remove();
}

/**
 *
 * Logs tab
 *
 **/
export function showLogsDetails(rowData, colSpan) {
  const row = document.getElementById(`row-${rowData.id}`);

  // Change the text of the toggle button.
  document.getElementById(`toggle-details-${rowData.id}`).innerText = 'Hide details';

  // New row.
  const newRow = document.createElement('tr');
  newRow.setAttribute('id', `details-row-${rowData.id}`);

  // Add the new row after the selected row.
  row.parentNode.insertBefore(newRow, row.nextSibling);

  // Create a td element that has a colSpan width (in our case 100%).
  const newTD = document.createElement('td');
  newTD.setAttribute('id', `details-td-${rowData.id}`);
  newTD.colSpan = colSpan;

  // Add the td to the row.
  newRow.appendChild(newTD);

  // Add the component in the td of the new row.
  const ComponentClass = Vue.extend(LogDetails);
  const instance = new ComponentClass({
    propsData: {row: rowData}
  });
  instance.$mount();
  newTD.append(instance.$el);
}

export function areLogDetailsOpen(rowId) {
  return !!document.getElementById(`details-row-${rowId}`);
}

/**
 *
 * Issues tab
 *
 **/
export function showIssueDetails(rowData, colSpan) {
  const row = document.getElementById(`row-${rowData.id}`);

  // Change the text of the toggle button.
  document.getElementById(`toggle-issue-details-${rowData.id}`).innerText = 'Hide details';

  // New row.
  const newRow = document.createElement('tr');
  newRow.setAttribute('id', `issue-details-row-${rowData.id}`);

  // Add the new row after the selected row.
  row.parentNode.insertBefore(newRow, row.nextSibling);

  // Create a td element that has a colSpan width (in our case 100%).
  const newTD = document.createElement('td');
  newTD.setAttribute('id', `issue-details-td-${rowData.id}`);
  newTD.colSpan = colSpan;

  // Add the td to the row.
  newRow.appendChild(newTD);

  // Add the component in the td of the new row.
  const ComponentClass = Vue.extend(IssueDetails);
  const instance = new ComponentClass({
    propsData: {row: rowData}
  });
  instance.$mount();
  newTD.append(instance.$el);
}

export function areIssueDetailsOpen(rowId) {
  return !!document.getElementById(`issue-details-row-${rowId}`);
}
